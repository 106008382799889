import 'lib/util/scripts';
/**
 * FIXME: UXTASK-666 Kill off global sass.
 * The following images sass inject global image css, and associated classnames.
 * This payload is loaded for all users even if the user never sees the image.
 * In addition, the classnames are global, sometimes generically and impossible to find.
 * Move images to be imported directly where they are used, and deprecate the following 5 sass imports.
 * Consider wrapping with `isLegacyCssLoadingEnabled()`
 */
import 'static/image_sass_proxy/images.sass';
import 'static/image_sass_proxy/images-discovery.sass';
import 'static/image_sass_proxy/images-pgx.sass';
import 'static/image_sass_proxy/images-research.sass';
import 'static/image_sass_proxy/images2.sass';

import { Environment, getEnvironment, initMonitoring, loggerUtils, retry } from '@color/lib';
import { StrictMode } from 'react';
import ReactDOM from 'react-dom';

import { isLegacyCssLoadingEnabled } from 'lib/deprecatedCssUtils/waffle';
import { loadPolyfills } from 'lib/polyfills/loadPolyfills';
import { getMonitoringConfig } from 'lib/util/monitoring/config';

import { BaseApp } from './ui/App/BaseApp';

const environment: Environment = getEnvironment();
initMonitoring(getMonitoringConfig(environment));

/**
 * @deprecated. Instead of CSS or SASS, use MaterialUI components or CSS-in-JS
 * styled components.
 * Global Mutable Lazy loaded CSS is unpredictable, and has caused numerous bugs.
 * See https://getcolor.atlassian.net/browse/UXTASK-666
 */
async function loadDeprecatedCss() {
  if (isLegacyCssLoadingEnabled()) {
    /**
     * Lazy load deprecated CSS. Unlike JavaScript code, CSS cannot be directly lazy loaded
     * in our WebPack configuration. This function allows us to lazy load the JavaScript
     * code that then imports the CSS. In doing so, Webpack converts that CSS into a lazy
     * loaded bundle that can be conditionally loaded.
     */
    loggerUtils.logMessageToConsole('Loading global CSS.');
    await retry(() => import('./deprecatedCss'));
  } else {
    loggerUtils.logMessageToConsole('Skipped loading global CSS.');
  }
}
export async function initializeReactApp() {
  await loadPolyfills();
  const ConfiguredBaseApp = await BaseApp();
  ReactDOM.render(
    <StrictMode>
      <ConfiguredBaseApp />
    </StrictMode>,
    document.getElementById('root') as HTMLElement
  );
  await loadDeprecatedCss();
}
